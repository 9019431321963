import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import Home from './pages/main/Home';
import AboutUs from './pages/main/AboutUs';
import Pricing from './pages/main/Pricing';
import SignUp from './pages/main/SignUp';
import SignIn from './pages/main/SignIn';
import DashboardHome from './pages/dashboard/DashboardHome';
import VerificationSent from './pages/main/VerificationSent';
import EmailVerified from './pages/main/EmailVerified';
import DashboardContentMain from './pages/dashboard/DashboardContentMain';
import FirstSignIn from './pages/main/FirstSignIn';
import DashboardContentGrants from './pages/dashboard/DashboardContentGrants';
import ProtectedRoute from './components/ProtectedRoute';
import PublicRoute from './components/PublicRoute';
import Footer from './components/Footer';


function Main() {
  const location = useLocation();

  // Hide navbar and footer for these routes
  const hideFooterAndNavbarRoutes = [
      '/signup',
      '/signin',
      '/add-organization',
  ];

  // Check if the current route matches any hideFooterAndNavbarRoutes or starts with /dashboard
  const shouldHideFooterAndNavbar =
      hideFooterAndNavbarRoutes.includes(location.pathname) || location.pathname.startsWith('/dashboard');

  return (
      <>
          {!shouldHideFooterAndNavbar && <NavigationBar />}
          <Routes>
              {/* Public Routes */}
              <Route
                  path="/"
                  element={
                      <PublicRoute>
                          <Home />
                      </PublicRoute>
                  }
              />
              <Route
                  path="/about-us"
                  element={
                      <PublicRoute>
                          <AboutUs />
                      </PublicRoute>
                  }
              />
              <Route
                  path="/pricing"
                  element={
                      <PublicRoute>
                          <Pricing />
                      </PublicRoute>
                  }
              />
              <Route
                  path="/signup"
                  element={
                      <PublicRoute>
                          <SignUp />
                      </PublicRoute>
                  }
              />
              {/* <Route
                  path="/signin"
                  element={
                      <PublicRoute>
                          <SignIn />
                      </PublicRoute>
                  }
              /> */}
              <Route
                  path="/verification-sent"
                  element={
                      <PublicRoute>
                          <VerificationSent />
                      </PublicRoute>
                  }
              />
              <Route
                  path="/confirm"
                  element={
                      <PublicRoute>
                          <EmailVerified />
                      </PublicRoute>
                  }
              />
              {/* <Route
                  path="/add-organization"
                  element={
                      <PublicRoute>
                          <FirstSignIn />
                      </PublicRoute>
                  }
              /> */}

              {/* Protected Dashboard Routes */}
              <Route
                  path="/dashboard/*"
                  element={
                      <ProtectedRoute>
                          <DashboardHome />
                      </ProtectedRoute>
                  }
              >
                  <Route path="home" element={<DashboardContentMain />} />
                  <Route path="grants" element={<DashboardContentGrants />} />

                  <Route path="*" element={<Navigate to="/dashboard/home" />} />
              </Route>

              {/* Redirect Undefined Routes */}
              <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          {!shouldHideFooterAndNavbar && <Footer />}
      </>
  );
}

function App() {
  return (
    <Router>
      <Main />
    </Router>
  );
}

export default App;
