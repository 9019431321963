import { Outlet } from 'react-router-dom';
import './DashboardHome.css'
import DashboardNavbar from '../../components/DashboardNavbar';
import DashboardSidebar from '../../components/DashboardSidebar';

const DashboardHome = () => {
    return (
        <div className="dashboard-container">
            <DashboardNavbar />
            <div className="content-area">
                <DashboardSidebar/>
                <div className="main-content">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default DashboardHome;
