import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Form, Input, Button, Checkbox, message } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';

import './SignUp.css'
import logo from '../../assets/logo.png'

import axiosInstance from "../../api/axiosInstance";


const SignUp = () => {

    const [form] = Form.useForm();
    const [isDisable, setIsDisable] = useState(false);
    const navigate = useNavigate()

    const onFinish = async (values) => {
        try {
            setIsDisable(true);
            const response = await axiosInstance.post('/auth/signup', {
                name: values.name,
                surname: values.surname,
                email: values.email,
                password: values.password,
            });
            navigate("/verification-sent");
            
        } catch (error) {
            if (error.response && error.response.status === 409) {
                form.setFields([{
                    name: 'email',
                    errors: ['Email already exists'],
                }]);
            }
            setIsDisable(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="signup-container">
            <div className="signup-box">
                <div className="logo-container">
                    <img src={logo} alt="Company Logo" className="company-logo" />
                </div>
                <Form
                    form={form}
                    name="signup"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="name"
                        rules={[
                            { required: true, message: 'Please input your name' },
                            { pattern: /^[A-Za-z\s]+$/, message: 'Name can only contain letters' }
                        ]}
                    >
                        <Input placeholder="Name" />
                    </Form.Item>

                    <Form.Item
                        name="surname"
                        rules={[
                            { required: true, message: 'Please input your surname' },
                            { pattern: /^[A-Za-z\s]+$/, message: 'Surname can only contain letters' }
                        ]}
                    >
                        <Input placeholder="Surname" />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: 'Please input your Email' },
                            { type: 'email', message: 'Please enter a valid email address' }
                        ]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            { required: true, message: 'Please input your password' },
                            { min: 8, message: 'Password must be at least 8 characters' },
                            { pattern: /(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?_":{}|<>])/, message: 'Password must contain at least one uppercase letter, one number, and one special character' }
                        ]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>

                    <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject('You must agree to the terms and conditions!') }]}
                    >
                        <Checkbox>
                            I agree to the <a href="#" style={{ color: '#00b080' }}>Terms and Conditions</a> and <a href="#" style={{ color: '#00b080' }}>Privacy Policy</a>
                        </Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="signup-button" disabled={isDisable}>
                            Sign Up
                        </Button>
                    </Form.Item>
                    {/* <Form.Item>
                        <Button type="primary" className="google-signup-button" icon={<GoogleOutlined />} disabled={isDisable}>
                            Sign Up with Google
                        </Button>
                    </Form.Item> */}
                </Form>
            </div>
        </div>
    )
}

export default SignUp;