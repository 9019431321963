import React from "react";
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h4>Contact Us</h4>
                    <p>Email: <a href="mailto:info@ai4grants.net">info@ai4grants.net</a></p>
                    
                </div>
                <div className="footer-section">
                    <h4>Quick Links</h4>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/about-us">About Us</a></li>
                        <li><a href="/pricing">Pricing</a></li>
                        {/* <li><a href="/signin">Sign In</a></li> */}
                    </ul>
                </div>
                
            </div>
            <div className="footer-bottom">
                <p>© 2024 ai4grants. All Rights Reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
