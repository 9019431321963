import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axiosInstance from '../../api/axiosInstance';

import { Result, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './EmailVerified.css'

const EmailVerified = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const confirmEmail = async () => {
            try {
                const response = await axiosInstance.post(`/confirm-account?token=${token}`);
                setError(false);
            } catch (error) {
                console.error("Error confirming email:", error);
                setError(true);
            }
        };

        if (token) {
            setLoading(true);
            confirmEmail();
            setLoading(false);
        }
    }, [token]);

    return (
        <div className='email-verified-container'>
            {
                loading ? (
                    <Spin indicator={<LoadingOutlined style={{
                        fontSize: 48,
                      }}
                      spin />}
                      />
                ) : error ? (
                    <Result
                        status="error"
                        title="Your Email is not Verified, try again please"
                    />
                ) : (
                    <Result
                        status="success"
                        title="Your Email is Verified"
                    />
                )
            }

        </div>
    );
};

export default EmailVerified;