import React from "react";
import './Pricing.css';
import { Row, Col, Card, Button } from "antd";

const Pricing = () => {
  return (
    <div className="pricing-container" style={{ padding: '50px', textAlign: 'center' }}>
      <h1>Our Pricing Plans</h1>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12} lg={6} style={{ display: 'flex' }}>
          <Card
            title="Basic"
            bordered={true}
            style={{ width: '100%', minHeight: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            cover={
              <div>
                <h2>Free</h2>
              </div>
            }
          >
            {/* <Button style={{ marginBottom: '42px' }} type="primary" block>Choose Basic</Button> */}
            <ul style={{ textAlign: 'left' }}>
              <li>Access to basic features</li>
              <li>AI-powered grant discovery</li>
              <li>Basic support</li>
            </ul>
          </Card>
        </Col>

        {/* Pro Plan */}
        <Col xs={24} sm={12} lg={6}>
          <Card
            title="Premium"
            bordered={true}
            style={{ width: '100%', minHeight: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            cover={
              <div>
                <h2>$14.99</h2>
                <p>/ month</p>
              </div>
            }
          >
            {/* <Button style={{ marginBottom: '20px' }} type="primary" block>Choose Premium</Button> */}
            <ul style={{ textAlign: 'left' }}>
              <li>Everything in Basic</li>
              <li>Advanced AI tools</li>
              <li>Priority support</li>
              <li>Custom grant recommendations</li>
            </ul>
          </Card>
        </Col>

        {/* Premium Plan
        <Col xs={24} sm={12} lg={6}>
          <Card
            title="Enterprise"
            bordered={true}
            style={{ width: '100%', minHeight: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            cover={
              <div>
                <h2>Custom Pricing</h2>
                <p></p>
              </div>
            }
          >
            <Button type="primary" block>Contact Us</Button>
            <ul style={{ textAlign: 'left' }}>
              <li>Everything in Pro</li>
              <li>Full AI-powered application assistance</li>
              <li>Dedicated support</li>
              <li>Access to exclusive grants</li>
            </ul>

          </Card>
        </Col> */}
      </Row>
    </div>
  );
}

export default Pricing;