import { Link } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import { DashboardOutlined, FundOutlined, UserOutlined } from '@ant-design/icons';

import './DashboardSidebar.css'

const { Sider } = Layout;

const DashboardSidebar = () => {
    return (
        <div style={{borderRight:'1px solid #e2e2e2'}}>
            <Sider width={300} className='dashboard-sidebar'>
                <Menu mode="vertical" defaultSelectedKeys={['1']}>
                    <Menu.Item key="1" icon={<DashboardOutlined />}>
                        <Link to="/dashboard/home">Dashboard</Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<FundOutlined />}>
                        <Link to="/dashboard/grants">Grants</Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<UserOutlined />}>
                        <Link to="/dashboard/profile">Profile</Link>
                    </Menu.Item>
                </Menu>
            </Sider>
        </div>
    );
}

export default DashboardSidebar;