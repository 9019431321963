import React from "react";
import './AboutUs.css';

const AboutUs = () => {
    return (
        <div className="about-us">
            <div className="about-us-header">
                <h1>We Understand You!</h1>
                <p>Your success is our mission.</p>
            </div>
            <div className="context-container">
                <p>
                    Finding and applying for grants can be overwhelming, time-consuming, and often frustrating.
                </p>
                <p>
                    Our mission is to simplify this process using advanced AI technology.
                </p>
                <p>
                    Unlike traditional grant platforms, we offer more than just a search tool—we help you apply for grants with precision.
                </p>
            </div>
            <div className="about-us-footer">
                <p>Discover the smarter way to secure funding with us.</p>
            </div>
        </div>
    );
};

export default AboutUs;
