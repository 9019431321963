import React from "react";
import { Layout, Dropdown, Menu, message } from 'antd';
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import './DashboardNavbar.css';
import logo from '../assets/logo.png';

const { Header } = Layout;

const DashboardNavbar = () => {
    const navigate = useNavigate();

    // Logout Handler
    const handleLogout = () => {
        // Delete authToken from localStorage
        localStorage.removeItem('authToken');
        message.success("You have been logged out."); // Optional success message
        navigate('/'); // Redirect to home
    };

    // Dropdown Menu
    const menu = (
        <Menu>
            <Menu.Item key="1" icon={<LogoutOutlined />} onClick={handleLogout}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <div>
            <Header className="dashboard-header">
                <div className="dashboard-logo">
                    <img src={logo} alt="Logo" style={{ height: '40px' }} />
                </div>
                <div className="dashboard-nav-user-info">
                    <Dropdown overlay={menu} trigger={['click']}>
                        <UserOutlined style={{ marginRight: "40px", cursor: "pointer" }} />
                    </Dropdown>
                </div>
            </Header>
        </div>
    );
};

export default DashboardNavbar;
