import React from "react";
import { Result } from 'antd';
import './VerificationSent.css'

const VerificationSent = () => {
    return (
        <div className='verification-sent-container'>
            <Result
                status="info"
                title="Succesful"
                // subTitle="We'll inform you when we are ready!"
                subTitle="We've sent a verification email to the provided address. Please check your inbox and click the link to activate your account."
            />
        </div>
    );
};

export default VerificationSent;