import React, {useState, useEffect} from "react";
import { Card, Pagination, Row, Col, Button } from "antd"
import axiosInstance from "../../api/axiosInstance";
import './DashboardContentGrants.css'

const DashboardContentGrants = () => {

    const [grants, setGrants] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 16;

    useEffect(() => {
        const fetchGrants = async () => {
            try {
                const response = await axiosInstance.get("/get-grants");
                setGrants(response.data);
            } catch (error) {
                console.error("Error fetching grants:", error);
            }
        };
        fetchGrants();
    }, []);

    const startIndex = (currentPage - 1) * pageSize;
    const currentGrants = grants.slice(startIndex, startIndex + pageSize);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    };

    return (
        <div>
            <div className="dashboard-content-grants">
                <div className="title">All Grants</div>
                <Row gutter={[16, 16]}>
                    {currentGrants.map(grant => (
                        <Col xs={24} sm={12} md={12} key={grant.id}>
                            <Card 
                                title={grant.name} 
                                bordered={true} 
                                style={{borderWidth: '2px'}}
                                actions={[
                                    <Button
                                        type="link" key="edit"
                                        style={{ color: 'rgb(0, 176, 128)'}}
                                    >
                                        View Details
                                    </Button>
                                ]}
                            >
                                <p>Deadline: <strong>{new Date(grant.deadline_date).toLocaleDateString()}</strong></p>
                                <p>{truncateText(grant.description, 100)}</p> {/* Truncated description */}
                            </Card>
                        </Col>
                    ))}
                </Row>
                {/* Pagination */}
                <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={grants.length}
                    onChange={onPageChange}
                    style={{ marginTop: "16px", textAlign: "center", marginBottom:"32px" }}
                    align="center"
                />
            </div>
        </div>
    );
}

export default DashboardContentGrants;