import React, { useState } from 'react';
import { Layout, Menu, Button, Grid, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import './NavigationBar.css';
import logo from '../assets/logo.png';
import { useNavigate, useLocation } from 'react-router-dom';

const { Header } = Layout;

const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const screens = Grid.useBreakpoint();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const selectedKey =
    location.pathname === '/' ? 'home' : location.pathname.replace('/', '');

  const handleMenuClick = (e) => {
    if (e.key === 'home') {
      navigate('/');
    } else if (e.key === 'about') {
      navigate('/about-us');
    } else if (e.key === 'pricing') {
      navigate('/pricing');
    }
    setDrawerVisible(false);
  };

  const handleSignUpClick = () => {
    navigate('/signup');
    setDrawerVisible(false);
  };

  const handleSignInClick = () => {
    navigate('/signin');
    setDrawerVisible(false);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  return (
    <Header className="header">
      {!screens.md && (
        <Button
          className="menu-button"
          type="text"
          icon={<MenuOutlined />}
          onClick={showDrawer}
        />
      )}

      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>

      {screens.md ? (
        <>
          <Menu
            className="nav-menu"
            mode="horizontal"
            defaultSelectedKeys={[selectedKey]}
            onClick={handleMenuClick}
          >
            <Menu.Item key="home">Home</Menu.Item>
            <Menu.Item key="about">About Us</Menu.Item>
            <Menu.Item key="pricing">Pricing</Menu.Item>
          </Menu>

          <div className="buttons">
            <Button
              type="primary"
              className="sign-up"
              onClick={handleSignUpClick}
            >
              Sign Up
            </Button>
            {/* <Button className="sign-in" onClick={handleSignInClick}>
              Sign In
            </Button> */}
          </div>
        </>
      ) : (
        <Drawer
          placement="left"
          closable
          onClose={onClose}
          visible={drawerVisible}
          className="nav-drawer"
        >
          <Menu
            mode="vertical"
            defaultSelectedKeys={[selectedKey]}
            onClick={handleMenuClick}
          >
            <Menu.Item key="home">Home</Menu.Item>
            <Menu.Item key="about">About Us</Menu.Item>
            <Menu.Item key="pricing">Pricing</Menu.Item>
          </Menu>

          <div className="drawer-buttons">
            <Button
              type="primary"
              className="sign-up"
              onClick={handleSignUpClick}
              block
            >
              Sign Up
            </Button>
            {/* <Button
              className="sign-in"
              onClick={handleSignInClick}
              block
            >
              Sign In
            </Button> */}
          </div>
        </Drawer>
      )}
    </Header>
  );
};

export default NavigationBar;
