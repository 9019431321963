import React, { useRef } from 'react';
import './Home.css';
import { DownOutlined, SignatureOutlined} from '@ant-design/icons';
import { Button} from 'antd';

import searchLogo from '../../assets/search_white.svg'
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const discoverFaster = useRef(null);
  const AISupport = useRef(null);
  const tailored = useRef(null);
  const navigate = useNavigate();

  const handleDiscoverFaster = () => {
    discoverFaster.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleAISupport = () => {
    AISupport.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleTailored = () => {
    tailored.current.scrollIntoView({behavior:'smooth'});
  }

  const handleJoinNow = () => {
    navigate('/signup');
  }

  return (
    <div className="home-container">
      <header className="home-header">
        <h3>Welcome to ai4grants</h3>
        <h1>Smart Grants, Faster Growth</h1>
        <div className="learn-more" onClick={handleDiscoverFaster}>
          <h4>Learn More</h4>
          <DownOutlined />
        </div>
      </header>

      <section className="discover-faster" ref={discoverFaster}>
        <h2>Discover Grants Faster</h2>
        <div className="discover-content">
          {/* <div className="discover-icon-container">
            <img src={searchLogo} alt="Grant Icon" className="discover-icon" />
          </div> */}
          <div className="discover-text-container">
            <p>Our AI system continuously monitors new grant opportunities, making sure you never miss the chance to apply for relevant funding.</p>
          </div>
        </div>
        <div className="learn-more df-learn-more" onClick={handleAISupport}>
          <h4>Learn More</h4>
          <DownOutlined />
        </div>
      </section>

      <section className="ai-support" ref={AISupport}>
        <h2>AI-Driven Application Support</h2>
        <div className='ai-content'>
          {/* <div className="ai-icon-container">
            <img src={searchLogo} alt="Grant Icon" className="discover-icon" />
          </div> */}
          <div className='ai-text-container'>
            <p>With AI assistance, you’ll craft winning applications that match each grant's requirements—boosting your chances of success.</p>
          </div>
        </div>
        <div className="learn-more ai-learn-more" onClick={handleTailored}>
          <h4>Learn More</h4>
          <DownOutlined />
        </div>
      </section>

      <section className="tailored" ref={tailored}>
        <h2>Tailored to You</h2>
        <div className='tailored-content'>
          {/* <div className='tailored-icon-container'>
            <img src={searchLogo} alt="Grant Icon" className="discover-icon" />
          </div> */}
          <div className='tailored-text-container'>
            <p>Get matched with grants that fit your organization’s strengths and qualifications, saving you time and maximizing funding potential.</p>
          </div>
        </div>
        <div className="learn-more tailored-learn-more" onClick={handleJoinNow}>
          <h4 style={{fontSize:20}}>Join Now</h4>
          <SignatureOutlined style={{fontSize:30}}/>
          </div>
      </section>
    </div>
  );
};

export default Home;