import React, { useState, useEffect } from "react";

import { Card, Button, Form, Modal, Input } from "antd";

import './DashboardContentMain.css'
import axiosInstance from "../../api/axiosInstance";

const DashboardContentMain = () => {

    const [organization, setOrganization] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const getOrganization = async () => {
        try {
            const email = localStorage.getItem('user');
            const response = await axiosInstance.get(`/organization/get/${email}`);
            setOrganization(response.data);
        } catch (error) {
            console.error("Error fetching organization:", error);
        }
    };

    useEffect(() => {
        getOrganization();
    }, []);

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleOk = () => {
        form.validateFields()
            .then(async values => {
                try {
                    // Send a POST request to add the new request
                    const response = await axiosInstance.post(`/request/add/${organization.id}`, {
                        title: values.title,
                        description: values.description,
                    });
                    console.log("Request added successfully:", response.data);

                    // Optionally, update the UI with the new request
                    setOrganization(prevOrg => ({
                        ...prevOrg,
                        requests: [...prevOrg.requests, { title: values.title, description: values.description }]
                    }));

                    setIsModalVisible(false);
                    form.resetFields();
                } catch (error) {
                    console.error("Error adding request:", error);
                }
            })
            .catch(info => {
                console.error("Validation Failed:", info);
            });
    };

    return (
        <div className="dashboard-content-main-container">
            <div className="content-container">
                <div className="content-container-title"> Grant Search Requests </div>
                {organization ? (
                    organization.requests && organization.requests.length > 0 ? (
                        organization.requests.map((request, index) => (
                            <Card
                                key={index}
                                title={request.title}
                                style={{ marginBottom: '16px', borderWidth: '2px' }}
                                actions={[
                                    <Button
                                        type="link" key="edit"
                                        style={{ color: 'rgb(0, 176, 128)'}}
                                    >
                                        View Matching Grants
                                    </Button>
                                ]}
                            >
                                {truncateText(request.description, 100)} {/* Limit to 100 characters */}
                            </Card>
                        ))
                    ) : (
                        <p style={{ color: "#8d8d8d" }}>It looks like there are no requests. Create one to get started!</p>
                    )
                ) : (
                    <p>Loading requests...</p>
                )}
            </div>
            <div className="profile-container">
                <Card
                    style={{ borderWidth: '2px' }}
                >
                    <div className="profile-title">
                        {organization ? organization.name : "Loading..."}
                    </div>
                    <div className="request-button">
                        <Button type="primary" onClick={showModal}>
                            Create New Request
                        </Button>
                    </div>
                </Card>
            </div>
            <Modal
                okButtonProps={{ style: { backgroundColor: 'rgb(0, 176, 128)' } }}
                title="Create New Request"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form form={form} layout="vertical" name="requestForm">
                    <Form.Item
                        name="title"
                        label="Title"
                        rules={[{ required: true, message: 'Please enter a title' }]}
                    >
                        <Input placeholder="Enter request title" />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[{ required: true, message: 'Please enter a description' }]}
                    >
                        <Input.TextArea placeholder="Enter request description" rows={4} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}


export default DashboardContentMain;